import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const postLogin = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/auth/login`,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json'
  // },
  data: {
    username: payload.username,
    password: payload.password
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storePostLogin(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default postLogin;
