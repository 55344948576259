import React from 'react';

import LogRocket from 'logrocket';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import TripInfo from '../components/TripInfo';
import BackgroundIllustration from '../components/BackgroundIllustration';

import { handleEndpointErrors, identifyLogRocketUser, SNACKBAR_TIME } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
  },
  noTrip: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      marginTop: 'calc(50vh - 120px)',
      position: 'relative',
    },
  }
}));

const Home = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const trip = useStoreState(state => state.trips.currentItem);
  const tripsLoading = useStoreState(state => state.trips.loading);
  // const tripsError = useStoreState(state => state.trips.error);
  const getCurrentTrip = useStoreActions(actions => actions.trips.getCurrentTrip);
  const postTrip = useStoreActions(actions => actions.trips.postTrip);
  const updateTripPassenger = useStoreActions(actions => actions.trips.updateTripPassenger);

  const [starTripClicked, setStartTrip] = React.useState(false);
  const [endTripClicked, setEndTrip] = React.useState(false);
  const [startModalOpen, setStartModalOpen] = React.useState(false);

  React.useEffect(() => {
    getCurrentTrip().then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log(tripsState);
        const currentTrip = tripsState.currentItem;
        const routeId = currentTrip.route?.id;
        // Route IDs from line with ID 7
        const trackRouteIds = [136, 138, 140, 144, 145, 146];
        if ( process.env.REACT_APP_LOGROCKET && routeId && trackRouteIds.includes(routeId) ) {
          const tripId = currentTrip?.id;
          const vehicleId = currentTrip.vehicle?.id;
          const departureDate = (currentTrip?.departureDate || '').split('T')[0];
          LogRocket.init(process.env.REACT_APP_LOGROCKET);
          identifyLogRocketUser(tripId, routeId, vehicleId, departureDate);
        }
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartTrip = () => {
    const notBoardedPassengers = trip.passengers?.filter(passenger => passenger.status !== 'onboard');
    if (trip.route.direction === 'out' && notBoardedPassengers?.length && !startModalOpen) {
      setStartModalOpen(true);
    } else {
      if( !starTripClicked && !startModalOpen ) {
        setStartTrip(true);
      } else {
        setStartModalOpen(false);
        setSnackbar({ show: true, severity: 'info', message: t('home.updatingTrip') });
        postTrip({ id: trip.id, status: 'started' }).then(() => {
          const tripsState = store.getState().trips;
          if (!tripsState.loading && !tripsState.error) {
            localStorage.setItem('startedTripDate', new Date().toISOString());
            getCurrentTrip().then(() => {
              const tripsState = store.getState().trips;
              if (!tripsState.loading && !tripsState.error) {
                setSnackbar({ show: false });
                // console.log(tripsState);
              } else {
                handleEndpointErrors(tripsState, props, setSnackbar, t);
              }
            });
          } else {
            if ( tripsState.error?.status === 400 ) {
              setSnackbar({ show: false });
              getCurrentTrip();
            } else {
              handleEndpointErrors(tripsState, props, setSnackbar, t);
            }
          }
        });
      }
    }
  }

  const handleEndTrip = () => {
    if( !endTripClicked ) {
      setEndTrip(true);
    } else {
      setSnackbar({ show: true, severity: 'info', message: t('home.updatingTrip') });
      postTrip({ id: trip.id, status: 'completed' }).then(() => {
        const tripsState = store.getState().trips;
        if (!tripsState.loading && !tripsState.error) {
          setSnackbar({ show: false });
          // console.log('success', tripsState);
        } else {
          if ( tripsState.error?.status === 400 ) {
            setSnackbar({ show: false });
            getCurrentTrip();
          } else {
            handleEndpointErrors(tripsState, props, setSnackbar, t);
          }
        }
      });
    }
  }

  const handlePassengerChange = passenger => {
    const rules = {
      'ready': {
        'in': {
          'approved': 'onboard',
          'onboard': 'approved'
        },
        'out': {
          'approved': 'onboard',
          'onboard': 'approved'
        }
      },
      'started': {
        'in': {
          'approved': 'underway',
          'underway': 'finished'
        },
        'out': {
          'pending': 'finished',
          'approved': 'finished',
          'onboard': 'finished',
          'underway': 'finished'
        }
      }
    };

    const direction = trip.route.direction.toLowerCase();
    const tripStatus = ['incident', 'delayed'].includes(trip.status) ? 'started' : trip.status === 'confirmed' ? 'ready' : trip.status;
    let status = rules[tripStatus][direction][passenger.status];

    updateTripPassenger({ passengerId: passenger.id, status }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loadingPassenger && !tripsState.errorPassenger) {
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.passenger.updated', { passenger: passenger.name }) });
      } else {
        tripsState.error = tripsState.errorPassenger;
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const handlePassengerSkip = passenger => {
    updateTripPassenger({ passengerId: passenger.id, status: 'cancelled', notes: 'Passenger skipped' }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loadingPassenger && !tripsState.errorPassenger) {
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.passenger.updated', { passenger: passenger.name }) });
      } else {
        tripsState.error = tripsState.errorPassenger;
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const t = useTranslation();

  storeMenuTitle(t('home.pageTitle'));

  return (
    !tripsLoading ?
      ['ready', 'confirmed'].includes(trip.status) ? (
        <React.Fragment>
          <TripInfo
            trip={ trip }
            onTripBtnClick={ handleStartTrip }
            tripButtonText={ !starTripClicked ? t('home.start.label') : t('home.start.confirm') }
            onPassengerCheckboxChange={ handlePassengerChange }
            onPassengerSkip={ handlePassengerSkip }
          />
          <Dialog open={ startModalOpen } onClose={ () => setStartModalOpen(false) }>
            <DialogTitle>{ t('home.startModal.title') }</DialogTitle>
            <DialogContent>
              <DialogContentText>{ t('home.startModal.body') }</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={ () => setStartModalOpen(false) } color="primary">{ t('home.startModal.btnCancel') }</Button>
              <Button onClick={ () => handleStartTrip() } color="primary">{ t('home.startModal.btnConfirm') }</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      ) : ['started', 'delayed', 'incident'].includes(trip.status) ? (
        <TripInfo
          trip={ trip }
          onTripBtnClick={ handleEndTrip }
          tripButtonText={ !endTripClicked ? t('home.end.label') : t('home.end.confirm') }
          onPassengerCheckboxChange={ handlePassengerChange }
          onPassengerSkip={ handlePassengerSkip }
        />
      ) : (
        <div className={ classes.noTrip }>
          <BackgroundIllustration />
          <Typography variant="h6">{ t('home.noTrip') }</Typography>
        </div>
      )
    : <LinearProgress />
  );
}

export default Home;
