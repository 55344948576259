import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CommuteIcon from '@material-ui/icons/Commute';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';

import Status from '../components/Status';
import Tag from '../components/Tag';
import Route from '../components/Route';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '12px',
    boxShadow: '0px 16px 80px 0px rgba(0, 0, 0, 0.16)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiCardActions-root': {
      justifyContent: 'space-between',
      padding: `0 ${ theme.spacing(2) }px ${ theme.spacing(1) }px`,
    },
    '& .MuiIconButton-root': {
      marginLeft: theme.spacing(-1.5),
    },
    '& .MuiButton-text': {
      marginLeft: theme.spacing(-1),
    },
    '& .MuiIconButton-root + .MuiButton-text': {
      marginLeft: theme.spacing(1),
    },
    '& hr': {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: `1px solid ${ theme.palette.divider }`,
      margin: '14px 0',
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      verticalAlign: 'middle',
    }
  },
  absolute: {
    position: 'absolute',
    right: 0,
  },
  routeName: {
    marginBottom: '11px',
  },
  tags: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '12px',
    position: 'relative',
  },
  date: {
    top: 0,
  },
  ticket: {
    top: '20px',
  },
  cardBack: {
    paddingBottom: '62px',
    '& .MuiTypography-subtitle1': {
      paddingRight: '100px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.light,
      fontSize: '40px',
      position: 'absolute',
      right: '22px',
      top: '25px',
    },
  },
  backPrimaryBtn: {
    backgroundColor: theme.palette.error.dark,
    borderTopRightRadius: '32px',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(-2),
    padding: '22px 34px',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const CustomCard = (props) => {
  const classes = useStyles();
  const {
    type,
    subtitle,
    title,
    route,
    date,
    ticket,
    vehicle,
    passengers,
    body,
    body2,
    status,
    tags,
    primaryIconBtn,
    secondaryBtn,
    backPaddingBottom,
    backMsg,
    backIcon,
    notFoundMsg
  } = props;

  const [state, setState] = React.useState({
    clickedPrimary: false
  });

  return (
    type !== 'notFound' ? (
      <Card className={ classes.card }>
        { !state.clickedPrimary ? (
          <React.Fragment>
            {/* <CardActionArea onClick={ () => secondaryBtn && secondaryBtn.handler() }> */}
              <CardContent>
              { route.name ? <Typography className={ classes.routeName } variant="caption" component="p">{ route.name }</Typography> : null }
                <div className={ classes.tags }>
                  { status ? <Status status={ status } /> : null }
                  { tags && tags.map((tag, index) => (
                    <Tag key={ index } name={ tag } />
                  )) }
                  { date ? <Typography className={ clsx( classes.absolute, classes.date ) } variant="body2" color="textSecondary" component="p">{ date }</Typography> : null }
                  { ticket ? <Typography className={ clsx( classes.absolute, classes.ticket ) } variant="body2" color="textSecondary" component="p">{ ticket }</Typography> : null }
                </div>
                { subtitle ? <Typography variant="body2" color="textSecondary" component="p">{ subtitle }</Typography> : null }
                { title ? <Typography gutterBottom variant="h6" component="p">{ title }</Typography> : null }
                { route ? <Route origin={ route.origin } destination={ route.destination } /> : null }
                { vehicle || passengers !== undefined ? <hr /> : null }
                { vehicle ? <Typography variant="body2" color="textSecondary" component="p"><CommuteIcon /> { vehicle }</Typography> : null }
                { passengers !== undefined ? <Typography variant="body2" color="textSecondary" component="p"><PeopleIcon /> { passengers }</Typography> : null }
                { body ? <Typography variant="body2" color="textSecondary" component="p">{ body }</Typography> : null }
                { body2 ? <Typography variant="body2" color="textSecondary" component="p">{ body2 }</Typography> : null }
              </CardContent>
            {/* </CardActionArea> */}
            { primaryIconBtn || secondaryBtn ? <CardActions>
              { primaryIconBtn ? <IconButton onClick={ () => setState({ ...state, clickedPrimary: true }) }>{ primaryIconBtn.icon }</IconButton> : null }
              { secondaryBtn ? <Button color="primary" onClick={ secondaryBtn.handler }>{ secondaryBtn.text }</Button> : null }
            </CardActions> : null }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CardActionArea>
              <CardContent className={ classes.cardBack } style={{ paddingBottom: backPaddingBottom }}>
                <Typography variant="subtitle1"  component="p">{ backMsg }</Typography>
                { backIcon }
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button className={ classes.backPrimaryBtn } variant="contained" onClick={ primaryIconBtn.handler }>{ primaryIconBtn.backText }</Button>
              <Button color="primary" onClick={ () => setState({ ...state, clickedPrimary: false }) }>{ secondaryBtn.backText }</Button>
            </CardActions>
          </React.Fragment>
        ) }
      </Card>
    ) : (
      <Card className={ classes.card }>
        <CardActionArea>
          <CardContent>
            <Typography variant="body1" component="h2">{ notFoundMsg }</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  );
}

CustomCard.propTypes = {
  type: PropTypes.string,
  route: PropTypes.exact({
    name: PropTypes.string,
    origin: PropTypes.object.isRequired,
    destination: PropTypes.object.isRequired,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  ticket: PropTypes.string,
  vehicle: PropTypes.string,
  passengers: PropTypes.number,
  body: PropTypes.string,
  body2: PropTypes.string,
  status: PropTypes.string,
  tags: PropTypes.array,
  primaryIconBtn: PropTypes.exact({
    icon: PropTypes.element.isRequired,
    handler: PropTypes.func.isRequired,
    backText: PropTypes.string.isRequired,
  }),
  secondaryBtn: PropTypes.exact({
    text: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    backText: PropTypes.string.isRequired,
  }),
  backMsg: PropTypes.string,
  backPaddingBottom: PropTypes.string,
  backIcon: PropTypes.element,
  notFoundMsg: PropTypes.string,
};

export default CustomCard;
