import * as mqtt from 'react-paho-mqtt';

let client;
let clientId = window.URL.createObjectURL(new Blob([])).split('/').pop();
let connectionId;

export const mqttService = {
  connect: (
    id = '',
    topic = { ident: '', options: {} },
    onMessageArrived
  ) => {
    connectionId = id;

    // called when client lost connection
    const onConnectionLost = responseObject => {
      if (responseObject.errorCode !== 0) {
        // console.log(`${ connectionId } - onMqttConnectionLost: ` + responseObject.errorMessage);
      }
    };

    // console.log("clientId: " + clientId);
    client = mqtt.connect(
      process.env.REACT_APP_MQTT_HOST,
      Number(process.env.REACT_APP_MQTT_PORT),
      `${ clientId }-${ connectionId }`,
      onConnectionLost,
      onMessageArrived
    );

    client.connect({
      userName: process.env.REACT_APP_MQTT_USERNAME,
      onSuccess: () => {
        console.log(`${ connectionId } - connected!`);
        client.subscribe(topic.ident, topic.options);
      },
      useSSL: process.env.NODE_ENV === 'production'
    });
  },

  disconnect: () => {
    console.log(`${ connectionId } - disconnected!`);
    if (client.isConnected()) {
      client.disconnect();
    }
  },

  isConnected: () => client.isConnected()
}
