import { createStore } from 'easy-peasy';

import globalModel from './models/global';
import loginModel from './models/login';
import notificationsModel from './models/notifications';
import tripsModel from './models/trips';

const storeModel = {
  global: globalModel,
  login: loginModel,
  notifications: notificationsModel,
  trips: tripsModel
};

const store = createStore(storeModel);

export default store;
