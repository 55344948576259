import React from 'react';

import { fade, withTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles(theme => ({
// }));

const ToIcon = props => {
  // const classes = useStyles();

  return <SvgIcon { ...props }>
    <path d="M24 12C24 18.6274 12 28 12 28C12 28 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill={ fade(props.theme.palette.secondary.main, 0.12) } />
    <path opacity="0.6" d="M20 12C20 16.4183 12 23 12 23C12 23 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill={ fade(props.theme.palette.secondary.main, 0.32) } />
    <path d="M16 12C16 14.2091 12 18 12 18C12 18 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z" fill={ props.theme.palette.secondary.main } />
  </SvgIcon>;
}

export default withTheme(ToIcon);
