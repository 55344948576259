import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles(theme => ({
// }));

const SkipIcon = props => {
  // const classes = useStyles();

  return <SvgIcon { ...props }>
    <path d="M17.6569 8.34319L19.7783 6.22187L19.7783 11.8787L14.1214 11.8787L16.2427 9.7574C13.9022 7.41688 10.098 7.41688 7.75745 9.7574C7.04327 10.4716 6.54122 11.3272 6.27252 12.2323L4.20777 12.2323C4.52597 10.811 5.23308 9.45335 6.34323 8.34319C9.46865 5.21778 14.5315 5.21777 17.6569 8.34319Z" />
    <circle cx="12" cy="18" r="3" />
  </SvgIcon>;
}

export default SkipIcon;
