import React from 'react';

import { useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { mqttService } from '../services/mqtt/mqtt';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  snackbar: {
    left: '50%',
    maxWidth: '300px',
    position: 'fixed',
    top: '70px',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 9999,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NFC = () => {
  const classes = useStyles();
  const [lastMessage, setLastMessage] = React.useState({});

  const getCurrentTrip = useStoreActions(actions => actions.trips.getCurrentTrip);

  const t = useTranslation();

  const connectMqtt = () => {
    let timeout;

    mqttService.connect(
      'nfc',
      { ident: `${ process.env.REACT_APP_NFC_MQTT_TOPIC.replace('schema', localStorage.getItem('schema')) }/${ localStorage.getItem('id') }`, options: {} },
      message => {
        const jsonMessage = JSON.parse(message.payloadString);
        console.log('nfc', jsonMessage);
        if ([2000, 2010, 4020, 4030, 4040].includes(jsonMessage['status.code']) && jsonMessage['user.name']) {
          const severity = jsonMessage['status.code'] === 2010 ? 'success' : jsonMessage['status.code'] === 2000 ? 'info' : 'warning';
          let messageName = '';
          switch (jsonMessage['status.code']) {
            case 2000:
              messageName = 'userAdded'
              break;
            case 2010:
              messageName = 'ok'
              break;
            case 4020:
              messageName = 'userInactive'
              break;
            case 4030:
              messageName = 'userDeviceInactive'
              break;
            case 4040:
              messageName = 'extraTripInvalidReservation'
              break;
            case 4400:
              messageName = 'badRequest'
              break;
            case 4403:
              messageName = 'notAllowedToReserve'
              break;
            case 4421:
              messageName = 'reservationAlreadyExists'
              break;
            case 4423:
              messageName = 'reservationNotValidAutoCreate'
              break;
            default:
              messageName = ''
          }
          const role = jsonMessage['user.role'];
          setLastMessage({ text: jsonMessage['user.name'], severity, messageName, role });
          getCurrentTrip(false);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            setLastMessage({});
          }, 3000);
        }
      }
    );
  };

  React.useEffect(() => {
    setInterval(() => {
      if ( !mqttService?.isConnected() ) {
        connectMqtt();
      }
    }, 10000);

    connectMqtt();

    return () => mqttService.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (lastMessage?.text && lastMessage?.severity) ? (
    <div className={ classes.snackbar }><Alert severity={ lastMessage.severity }>{ t(`global.nfc.${ lastMessage.role }.${ lastMessage.messageName }`, { user: lastMessage.text }) }</Alert></div>
  ) : null;
}

export default NFC;
