import { withTheme, styled } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// sample
const CustomAppBar = styled(AppBar)({
  boxShadow: 'none',
  position: 'fixed',
  '& .MuiToolbar-root': {
    backgroundColor: props => props.theme.palette.background.default,
    borderBottom: props => `1px solid ${ props.theme.palette.divider }`,
    color: props => props.theme.palette.text.secondary,
  }
});

export default withTheme(CustomAppBar);
