import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles(theme => ({
// }));

const WazeIcon = props => {
  // const classes = useStyles();

  return <SvgIcon { ...props }>
    <path d="M19.54 3.63C20.23 4.57 20.69 5.67 20.89 6.82C21.1 8.07 21 9.34 20.58 10.54C20.18 11.71 19.5 12.76 18.58 13.6C17.91 14.24 17.15 14.77 16.32 15.18C16.73 16.25 16.19 17.45 15.12 17.86C14.88 17.95 14.63 18 14.38 18C13.27 18 12.35 17.11 12.31 16C12.05 16 9.73 16 9.24 16C9.13 17.14 8.11 18 6.97001 17.87C5.91001 17.77 5.11001 16.89 5.09001 15.83C5.10001 15.64 5.13001 15.44 5.19001 15.26C3.60001 14.73 2.21001 13.74 1.19001 12.41C0.860005 11.97 0.960005 11.34 1.42001 11C1.60001 10.86 1.82001 10.78 2.05001 10.78C2.77001 10.78 3.05 10.53 3.22001 10.15C3.46001 9.43 3.60001 8.68 3.61001 7.92C3.64001 7.39 3.70001 6.87 3.78001 6.35C4.13001 4.62 5.10001 3.07 6.50001 2C8.16001 0.7 10.19 0 12.29 0C13.72 0 15.13 0.35 16.4 1C17.64 1.62 18.71 2.5 19.54 3.63ZM15.72 14.31C17.5 13.5 18.9 12.04 19.59 10.21C21.21 5.27 17 1.05 12.29 1.05C11.94 1.05 11.58 1.07 11.23 1.12C8.36 1.5 5.40001 3.5 4.81001 6.5C4.43001 8.5 5.00001 11.79 2.05001 11.79C3.00001 13 4.32001 13.93 5.81001 14.37C6.66001 13.61 7.97001 13.69 8.74001 14.55C8.85001 14.67 8.94 14.8 9 14.94C9.59 14.94 12.2 14.94 12.55 14.94C13.07 13.92 14.33 13.5 15.35 14.04C15.5 14.12 15.6 14.21 15.72 14.31ZM9.97001 7.31C9.39001 7.34 8.88001 6.9 8.85001 6.31C8.82001 5.73 9.27001 5.23 9.85001 5.19C10.43 5.16 10.94 5.61 10.97 6.25C11 6.8 10.56 7.27 10 7.29L9.97001 7.31ZM14.66 7.31C14.08 7.34 13.57 6.9 13.54 6.31C13.5 5.73 13.96 5.23 14.54 5.19C15.12 5.16 15.63 5.61 15.66 6.25C15.68 6.8 15.25 7.27 14.66 7.29V7.31ZM8.71001 9.07C8.65001 8.79 8.84 8.5 9.12 8.45C9.4 8.4 9.68 8.58 9.74 8.86C10.09 9.97 11.16 10.69 12.32 10.6C13.46 10.66 14.5 9.96 14.89 8.88C15.03 8.62 15.35 8.5 15.6 8.65C15.78 8.75 15.89 8.92 15.89 9.12C15.7 9.83 15.26 10.45 14.66 10.88C13.97 11.36 13.16 11.63 12.32 11.64H12.21C10.58 11.71 9.11001 10.64 8.68001 9.06L8.71001 9.07Z" />
  </SvgIcon>;
}

export default WazeIcon;
