import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import LanguageIcon from '@material-ui/icons/Language';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import ReactCountryFlag from 'react-country-flag';

import BackgroundIllustration from '../components/BackgroundIllustration';

import { IS_STAGE } from '../shared/utilities';

import { setLanguage, useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
  },
  form: {
    margin: 'auto',
    maxWidth: '400px',
    overflow: 'hidden',
    padding: `15vh ${ theme.spacing(2) }px 0`,
    [`${ theme.breakpoints.down('sm') } and (orientation: landscape)`]: {
      padding: `5vh ${ theme.spacing(2) }px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: `10vh ${ theme.spacing(4) }px 0`,
    },
    '& > *': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    '& .MuiTextField-root': {
      '& label:not(.Mui-error)': {
        color: theme.palette.primary.main,
      },
      '& .MuiInputBase-root': {
        background: theme.palette.primary.contrastText,
        borderRadius: '12px',
        color: theme.palette.primary.main,
        '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline' : {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '12px',
        borderWidth: '2px',
      },
    },
    '& input:-webkit-autofill': {
      '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out',
      '-webkit-transition-delay': '9999s',
    },
  },
  username: {
    '& .MuiInputBase-input': {
      textTransform: 'lowercase',
    },
  },
  btnWrapper: {
    position: 'relative',
  },
  btnSubmit: {
    borderRadius: '40px',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    margin: `40px auto 0`,
    minHeight: '46px',
    minWidth: '144px',
    '&.Mui-disabled': {
      backgroundColor: `${ theme.palette.text.disabled } !important`,
    },
  },
  btnProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  globalVersion: {
    ...theme.typography.caption,
    bottom: theme.spacing(2),
    color: theme.palette.common.white,
    left: theme.spacing(2),
    position: 'absolute',
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.text.primary,
    },
  },
}));

const actions = [
  { icon: <ReactCountryFlag countryCode="US" svg style={{ width: '20px', height: '20px', }} />, name: 'English', language: 'en' },
  { icon: <ReactCountryFlag countryCode="CR" svg style={{ width: '20px', height: '20px', }} />, name: 'Español', language: 'es' },
];

const Login = (props) => {
  const classes = useStyles();

  const [direction] = React.useState('up');
  const [open, setOpen] = React.useState(false);
  const [hidden] = React.useState(false);

  const store = useStore();

  const globalVersion = useStoreState(state => state.global.version.version);
  const getUserMetadata = useStoreActions(actions => actions.global.getUserMetadata);

  const postLogin = useStoreActions(actions => actions.login.postLogin);
  const loginLoading = useStoreState(state => state.login.loading);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAction = (action) => event => {
    setOpen(false);
    setLanguage(action.language);
    localStorage.setItem('language', action.language);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <BackgroundIllustration />
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={values => {
          const errors = {};
          if ( !values.username ) {
            errors.username = t('login.username.errors.required');
          }
          if ( !values.password ) {
            errors.password = t('login.password.errors.required');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          postLogin({ username: values.username.toLowerCase(), password: values.password }).then(() => {
            const loginState = store.getState().login;
            if (!loginState.loading && !loginState.error && loginState.item.token) {
              const token = loginState.item.token;
              getUserMetadata(token).then(response => {
                setSubmitting(false);
                if (response?.role === 'driver') {
                  localStorage.setItem('token', token);
                  props.login(false);
                } else {
                  setErrors({ username: t('login.username.errors.invalid'), password: t('login.password.errors.invalid') });
                }
              });
            } else {
              setSubmitting(false);
              setErrors({ username: t('login.username.errors.invalid'), password: t('login.password.errors.invalid') });
            }
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={ classes.form }>
            <Field
              component={ TextField }
              name="username"
              label={ t('login.username.label') }
              color="secondary"
              className={ classes.username }
              variant="outlined"
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <Field
              component={ TextField }
              type="password"
              name="password"
              label={ t('login.password.label') }
              color="secondary"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <div className={ classes.btnWrapper }>
              <Button
                className={ classes.btnSubmit }
                color="primary"
                variant="contained"
                type="submit"
                disabled={ isSubmitting }
                onClick={ submitForm }
              >{ t('login.button') }</Button>
              { loginLoading && <CircularProgress size={ 24 } className={ classes.btnProgress } /> }
            </div>
          </Form>
        )}
      </Formik>
      <span className={ classes.globalVersion }>v{ globalVersion } ({ process.env.REACT_APP_LAST_COMMIT_SHA }){ IS_STAGE ? ' Stage' : '' }</span>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={ classes.speedDial }
        hidden={ hidden }
        icon={<SpeedDialIcon icon={ <LanguageIcon /> } openIcon={ <CloseIcon /> } />}
        onClose={ handleClose }
        onOpen={ handleOpen }
        open={ open }
        direction={ direction }
      >
        { actions.map(action => (
          <SpeedDialAction
            key={ action.name }
            icon={ action.icon }
            tooltipTitle={ action.name }
            onClick={ handleCloseAction(action) }
          />
        )) }
      </SpeedDial>
    </div>
  );
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
