import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NfcIcon from '@material-ui/icons/Nfc';
import PhoneIcon from '@material-ui/icons/Phone';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { formatDate, formatTime, sortByDisplayOrder, sortByPlaceTime } from '../shared/utilities';

import FromIcon from '../icons/From';
import ToIcon from '../icons/To';
import WazeIcon from '../icons/Waze';
import SkipIcon from '../icons/Skip';
import QrCodeIcon from '../icons/QrCode';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  info: {
    '& span': {
      padding: theme.spacing(0, 1),
    },
  },
  verticalSpacing: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:first-of-type': {
      marginTop: theme.spacing(1),
    },
  },
  icon: {
    height: '30px',
  },
  grow: {
    flexGrow: 1,
    '&.MuiGrid-item': {
      paddingRight: '60px',
    },
  },
  posRelative: {
    position: 'relative'
  },
  inputText: {
    ...theme.typography.h5,
  },
  time: {
    marginTop: '-8px',
  },
  line: {
    bottom: '-9px',
    fontSize: '26px',
    left: '10px',
    lineHeight: '26px',
    position: 'absolute',
    transform: 'rotate(90deg)',
    userSelect: 'none',
  },
  waze: {
    border: `1px solid ${ theme.palette.primary.light }`,
    padding: '7px',
    position: 'absolute',
    right: '12px',
    top: 0,
    '& .MuiIconButton-label': {
      position: 'relative',
      top: '2px',
    },
  },
  gridText: {
    color: theme.palette.text.secondary,
    '& + *': {
      textTransform: 'capitalize',
    },
  },
  gridLine: {
    backgroundColor: theme.palette.divider,
    height: '40px',
    width: '1px',
  },
  submitBtn: {
    borderRadius: '40px',
    display: 'flex',
    margin: `${theme.spacing(3)}px auto ${theme.spacing(2)}px`,
    minWidth: '200px',
  },
  passengers: {
    width: '100%',
    '& > .MuiFormLabel-root': {
      marginBottom: theme.spacing(1),
    },
    '& .MuiFormControlLabel-root': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      position: 'relative',
      '&:not(:first-child)::before': {
        backgroundColor: theme.palette.divider,
        content: '""',
        height: '1px',
        left: -theme.spacing(.6),
        position: 'absolute',
        top: 0,
        width: '100vw',
      },
    },
    '& .MuiCheckbox-root:not(.Mui-checked)': {
      color: theme.palette.primary.main,
    },
    '& .MuiCheckbox-root.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '& legend': {
      marginBottom: theme.spacing(1),
    },
    '& .Mui-checked': {
      position: 'static',
      '&::before': {
        backgroundColor: theme.palette.secondary.overlay,
        content: '""',
        height: '100%',
        left: '-5px',
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: -1,
      },
      '& .MuiIconButton-label': {
        color: theme.palette.secondary.dark,
      },
      '& + span .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
      }
    },
  },
  passengeresCapacity: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: 0,
    '& > div': {
      alignItems: 'center',
      display: 'flex',
      marginRight: theme.spacing(3),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(.5),
    },
  },
  passenger: {
    maxWidth: 'calc(100vw - 145px)',
  },
  passengerWithIcons: {
    maxWidth: 'calc(100vw - 195px)',
  },
  passengerWithManyIcons: {
    maxWidth: 'calc(100vw - 245px)',
  },
  passengerStatus: {
    fontSize: '10px',
    fontWeight: 500,
    position: 'absolute',
    right: '-12px',
    top: '7px',
  },
  passengerStatusAlone: {
    top: '22px',
  },
  passengerIcons: {
    position: 'absolute',
    right: '-25px',
    top: '18px',
    zIndex: 1,
  },
  passengerIconWaze: {
    '& .MuiIconButton-label': {
      position: 'relative',
      top: '2px',
    },
  },
}));

const TripInfo = props => {
  const classes = useStyles();
  const { trip, onTripBtnClick, tripButtonText, onPassengerCheckboxChange, onPassengerSkip } = props;
  const [skipPassenger, setSkipPassenger] = React.useState(null);
  const [isDisabledForMinutes, setIsDisabledForMinutes] = React.useState(false);

  const passengerDTDValidation = (passenger) => trip.route.type.toLowerCase() === 'dtd' &&
                                                 !['pending', 'finished', 'cancelled'].includes(passenger.status.toLowerCase()) &&
                                                 ['ready', 'started', 'incident', 'delayed'].includes(trip.status.toLowerCase());

  const passengerRsvSourceValidation = (passenger) => ['qr_code_driver', 'qr_code_passenger', 'nfc_reader'].includes(passenger.reservation.source);

  const passengerIconsValidation = (passenger) => passengerDTDValidation(passenger) || passengerRsvSourceValidation(passenger);

  const onSkipPassenger = passenger => {
    setSkipPassenger(null);
    onPassengerSkip(passenger);
  };

  const t = useTranslation();

  let intervalForMinutes = false;
  const checkDisabledForMinutes = (tripStatus, tripStatusDate, minutesToCheck = 1) => {
    if ( new Date(new Date().toISOString()).getTime() - new Date(tripStatusDate) < minutesToCheck*60*1000 ) {
      setIsDisabledForMinutes(true);
      if ( !intervalForMinutes ) {
        intervalForMinutes = setInterval(() => checkDisabledForMinutes(tripStatus, tripStatusDate, minutesToCheck), 10000);
      }
    } else {
      setIsDisabledForMinutes(false);
      clearInterval(intervalForMinutes);
    }
  };

  React.useEffect(() => {
    const tripStartedDate = localStorage.getItem('startedTripDate');
    if ( trip.status === 'started' && tripStartedDate ) {
      checkDisabledForMinutes(trip.status, tripStartedDate, 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  return (
    <div className={ classes.root }>
      <Typography variant="body2">{ trip.route.name }</Typography>
      <Typography variant="body2" color="textSecondary" className={ classes.info }>{`${ t('home.tripId') }: ${ trip.id }`}<span>|</span>{ formatDate(trip.departureDate, t) }</Typography>
      <Grid container spacing={ 1 } wrap="nowrap" alignItems="center" className={ `${ classes.posRelative }` }>
        <Grid item><FromIcon className={ classes.icon } /></Grid>
        <Grid item className={ classes.grow }>
          <TextField
            type="text"
            fullWidth
            multiline
            defaultValue={ trip.route.origin.description }
            InputProps={{ readOnly: true, disableUnderline: true, className: classes.inputText }}
          />
          <Typography className={ classes.time } variant="body2" color="textSecondary">{ formatTime(trip.schedule.departureTime) }</Typography>
        </Grid>
        <div className={ classes.line }>....</div>
        <IconButton className={ classes.waze } href={ `waze://?ll=${ trip.route.destination.latitude },${ trip.route.destination.longitude }&navigate=yes` } aria-label="Waze">
          <WazeIcon color="primary" />
        </IconButton>
      </Grid>

      <Grid container spacing={ 1 } wrap="nowrap" alignItems="center">
        <Grid item><ToIcon className={ classes.icon } /></Grid>
        <Grid item className={ classes.grow }>
          <TextField
            type="text"
            fullWidth
            multiline
            defaultValue={ trip.route.destination.description }
            InputProps={{ readOnly: true, disableUnderline: true, className: classes.inputText }}
          />
          <Typography className={ classes.time } variant="body2" color="textSecondary">{ formatTime(trip.schedule.arrivalTime) }</Typography>
        </Grid>
      </Grid>

      <Grid container justify="space-between" className={ classes.verticalSpacing }>
        <Grid item>
          <Typography variant="caption" component="p" className={ classes.gridText }>{ t('home.direction.label') }</Typography>
          <Typography variant="body2">{ trip.route.direction.toLowerCase() === 'in' ? t('home.direction.in') : t('home.direction.out') }</Typography>
        </Grid>
        <Grid item className={ classes.gridLine }></Grid>
        <Grid item>
          <Typography variant="caption" component="p" className={ classes.gridText }>{ t('home.type.label') }</Typography>
          <Typography variant="body2">{ trip.route.type.toLowerCase() === 'peripheral' ? t('home.type.peripheral') : t('home.type.doorToDoor') }</Typography>
        </Grid>
        <Grid item className={ classes.gridLine }></Grid>
        { trip.route.eta ? (
          <React.Fragment>
            <Grid item>
              <Typography variant="caption" component="p" className={ classes.gridText }>{ t('home.eta') }</Typography>
              <Typography variant="body2">{ trip.route.eta } min</Typography>
            </Grid>
            <Grid item className={ classes.gridLine }></Grid>
          </React.Fragment>
        )  : null }
        <Grid item>
          <Typography variant="caption" component="p" className={ classes.gridText }>{ t('home.distance') }</Typography>
          <Typography variant="body2">{ trip.route.km } km</Typography>
        </Grid>
        <Grid item className={ classes.gridLine }></Grid>
        <Grid item>
          <Typography variant="caption" component="p" className={ classes.gridText }>{ t('home.status') }</Typography>
          <Typography variant="body2">{ t(`global.status.${ trip.status }`) }</Typography>
        </Grid>
      </Grid>

      <Button
        className={ classes.submitBtn }
        type="submit"
        variant="outlined"
        color="primary"
        disabled={ isDisabledForMinutes }
        onClick={ onTripBtnClick }
      >{ tripButtonText }</Button>

      { trip.passengers && trip.passengers.length ? (
        <FormControl component="fieldset" className={ classes.passengers }>
          <FormLabel><Typography variant="body2" color="textPrimary">{ t('home.passengers') }</Typography></FormLabel>
          <div className={ classes.passengeresCapacity }>
            <div>
              <EventSeatIcon />
              <Typography variant="body2">{`${ trip.passengers.length }/${ trip.route.capacity }`}</Typography>
            </div>
            <div>
              <CheckIcon />
              <Typography variant="body2">{`${ trip.passengers.filter(passenger => ['onboard', 'underway'].includes(passenger.status)).length }`}</Typography>
            </div>
          </div>
          <FormGroup>
            { trip.passengers.sort((a, b) => trip.route.type.toLowerCase() === 'dtd' ? sortByDisplayOrder(a, b) : sortByPlaceTime(a, b)).map(passenger => (
              <FormControlLabel
                key={ `${ passenger.id }` }
                control={ <Checkbox
                            checked={ ['onboard', 'underway'].includes(passenger.status.toLowerCase()) }
                            onChange={ () => onPassengerCheckboxChange(passenger) }
                            disabled={ ['pending', 'finished', 'cancelled'].includes(passenger.status.toLowerCase()) }
                        /> }
                label={ <div>
                          <Typography 
                            className={ clsx(classes.passenger, {
                              [classes.passengerWithIcons]: passengerIconsValidation(passenger),
                              [classes.passengerWithManyIcons] : passengerDTDValidation(passenger) && passengerRsvSourceValidation(passenger) && trip.route.direction.toLowerCase() === 'in'
                            }) }
                            variant="body2"><strong>{ passenger.name }</strong></Typography>
                          <Typography
                            className={ clsx(classes.passenger, {
                              [classes.passengerWithIcons]: passengerIconsValidation(passenger),
                              [classes.passengerWithManyIcons] : passengerDTDValidation(passenger) && passengerRsvSourceValidation(passenger) && trip.route.direction.toLowerCase() === 'in'
                            }) }
                            variant="body2" gutterBottom>{ passenger.place?.description }</Typography>
                          <Typography
                            className={ clsx(classes.passengerStatus, { [classes.passengerStatusAlone]: !passengerIconsValidation(passenger) }) }
                            variant="overline"
                            style={{ color: props.theme.palette.status[passenger.status] ? props.theme.palette.status[passenger.status] : props.theme.palette.text.secondary }}
                          >
                            { t(`global.status.${ passenger.status }`) }
                          </Typography>
                          { passengerIconsValidation(passenger) && <div className={ classes.passengerIcons }>
                            { passengerRsvSourceValidation(passenger) && <React.Fragment>
                              { (passenger.reservation.source === 'qr_code_driver' || passenger.reservation.source === 'qr_code_passenger') && (
                                <IconButton aria-label="QR">
                                  <QrCodeIcon color="primary" />
                                </IconButton>
                              ) }
                              { passenger.reservation.source === 'nfc_reader' && (
                                <IconButton aria-label="NFC">
                                  <NfcIcon color="primary" />
                                </IconButton>
                              ) }
                            </React.Fragment> }
                            { passengerDTDValidation(passenger) && <React.Fragment>
                              { trip.route.direction.toLowerCase() === 'in' && (
                                <React.Fragment>
                                  <IconButton className={ classes.passengerIconSkip } onClick={ () => setSkipPassenger(passenger) } aria-label="Skip">
                                    <SkipIcon color="primary" />
                                  </IconButton>
                                  <IconButton className={ classes.passengerIconPhone } href={`tel:${ passenger.phoneNumber }`} aria-label="Phone">
                                    <PhoneIcon color="primary" />
                                  </IconButton>
                                </React.Fragment>
                              ) }
                              <IconButton className={ classes.passengerIconWaze } href={ `waze://?ll=${ passenger.place.latitude },${ passenger.place.longitude }&navigate=yes` } aria-label="Waze">
                                <WazeIcon color="primary" />
                              </IconButton>
                            </React.Fragment> }
                          </div> }
                        </div> }
              />
            )) }
          </FormGroup>
        </FormControl>
      ) : null }

      { !!skipPassenger && <Dialog open onClose={ () => setSkipPassenger(null) }>
        <DialogTitle>{ t('home.skipModal.title', { name: skipPassenger.name }) }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ t('home.skipModal.body', { name: skipPassenger.name }) }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setSkipPassenger(null) } color="primary">{ t('home.skipModal.btnCancel') }</Button>
          <Button onClick={ () => onSkipPassenger(skipPassenger) } color="primary">{ t('home.skipModal.btnConfirm') }</Button>
        </DialogActions>
      </Dialog> }
    </div>
  );
}

TripInfo.propTypes = {
  trip: PropTypes.shape({
    route: PropTypes.shape({
      origin: PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
      }),
      destination: PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
      }),
      direction: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      eta: PropTypes.number.isRequired,
      km: PropTypes.number.isRequired,
    }),
    passengers: PropTypes.array,
  }),
  onTripBtnClick: PropTypes.func.isRequired,
  tripButtonText: PropTypes.string.isRequired,
  onPassengerCheckboxChange: PropTypes.func.isRequired,
  onPassengerSkip: PropTypes.func.isRequired,
};

export default withTheme(TripInfo);
