import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { fade, makeStyles } from '@material-ui/core/styles';
// import AssessmentIcon from '@material-ui/icons/Assessment';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import CardTravelIcon from '@material-ui/icons/CardTravel';
// import Collapse from '@material-ui/core/Collapse';
import CropFreeIcon from '@material-ui/icons/CropFree';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import SearchIcon from '@material-ui/icons/Search';
// import SettingsIcon from '@material-ui/icons/Settings';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CustomAppBar from './CustomAppBar';
import MenuIllustration from './MenuIllustration';
import QRReader from './QRReader';
import Notifications from './Notifications';
import NFC from './NFC';

import { WHITE_LABEL, IS_STAGE, handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const reqAssetsImport = require.context('../white-label', true, /^\.\/.*\.svg$/);
const logo = reqAssetsImport(`./${ WHITE_LABEL }/logo.svg`);

function ListItemLink(props) {
  const { classes, icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink exact activeClassName="drawer-item-active" to={ to } ref={ ref } { ...itemProps } />),
    [to],
  );

  return (
    <li>
      <ListItem className={ classes } button component={ renderLink }>
        { icon ? <ListItemIcon>{ icon }</ListItemIcon> : null }
        <ListItemText primary={ primary } />
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.primary,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.contrastText, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.contrastText, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  drawer: {
    paddingTop: '30px',
    width: 320,
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
      borderBottomRightRadius: '40px',
      borderTopRightRadius: '40px',
    },
  },
  logo: {
    position: 'absolute',
    left: '16px',
    top: '15px',
  },
  userInfo: {
    '& p, & span': {
      display: 'block',
      maxWidth: '170px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  globalVersion: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    position: 'absolute',
    right: '36px',
    top: '20px',
  },
  theme: {
    position: 'absolute',
    right: '25px',
    top: '50px',
  },
  drawerItem: {
    color: theme.palette.primary.main,
    maxHeight: 'calc(100vh - 185px)',
    overflow: 'auto',
    zIndex: 1,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .drawer-item-active': {
      backgroundColor: theme.palette.secondary.overlay,
      borderBottomRightRadius: '40px',
      borderTopRightRadius: '40px',
      color: theme.palette.primary.main,
      maxWidth: 'calc(100% - 8px)',
      '& .MuiListItemIcon-root': {
        color: theme.palette.secondary.dark,
      },
    },
    [`${ theme.breakpoints.down('sm') } and (orientation: landscape)`]: {
      maxHeight: 'calc(100vh - 170px)',
    },
  },
  drawerLogout: {
    bottom: 0,
    color: theme.palette.primary.main,
    left: 0,
    position: 'absolute',
    width: '100%',
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    [`${ theme.breakpoints.down('sm') } and (orientation: landscape)`]: {
      color: theme.palette.primary.main,
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  sectionIcons: {
    display: 'flex'
  },
  nestedItem: {
    paddingLeft: theme.spacing(4),
  },
}));

const AppMenu = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
    moreOpen: false,
    notificactionsOpen: false,
    qrReaderOpen: false,
  });

  const store = useStore();

  const globalVersion = useStoreState(state => state.global.version.version);
  const theme = useStoreState(state => state.global.theme);
  const title = useStoreState(state => state.global.title);
  const setTheme = useStoreActions(actions => actions.global.setTheme);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const notifications = useStoreState(state => state.notifications.items);
  // const notificationsLoading = useStoreState(state => state.notifications.loading);
  // const notificationsError = useStoreState(state => state.notifications.error);
  const getNotifications = useStoreActions(actions => actions.notifications.getNotifications);
  const updateNotifications = useStoreActions(actions => actions.notifications.updateNotifications);

  const trip = useStoreState(state => state.trips.currentItem);

  // const toggleNestedItem = (item) => event => {
  //   event.stopPropagation();
  //   setState({ ...state, [item]: !state[item] });
  // };

  const callGetNotifications = () => {
    getNotifications().then(() => {
      const notificationsState = store.getState().notifications;
      if (!notificationsState.loading && !notificationsState.error) {
        // console.log(notificationsState);
      } else {
        handleEndpointErrors(notificationsState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    callGetNotifications();
    const interval = setInterval(() => callGetNotifications(), 300000);
    return () => clearInterval( interval );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open });
  };

  const handleThemeChange = (e) => {
    e.stopPropagation();
    setTheme(theme);
  };

  const handleNotificationsToggle = () => {
    setState({ ...state, notificactionsOpen: !state.notificactionsOpen });
    if (state.notificactionsOpen) {
      const readNotifications = notifications.map(notification => ({ id: notification.id, seen: true }));
      updateNotifications([...readNotifications]).then(() => {
        const notificationsState = store.getState().notifications;
        if (!notificationsState.loading && !notificationsState.error) {
          callGetNotifications();
          // console.log('error', notificationsState);
        } else {
          handleEndpointErrors(notificationsState, props, setSnackbar, t);
        }
      });

    } else {
      callGetNotifications();
    }
  };

  const handleQRReaderToggle = () => {
    setState({ ...state, qrReaderOpen: !state.qrReaderOpen });
  };

  const t = useTranslation();

  return (
    <div className={ classes.grow }>
      <CustomAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            className={ classes.menuButton }
            color="inherit"
            aria-label="open drawer"
            onClick={ toggleDrawer(true) }
          >
            <MenuIcon />
          </IconButton>
          <Typography className={ classes.title } variant="h6">{ title }</Typography>
          {/* <div className={ classes.search }>
            <div className={ classes.searchIcon }>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          <div className={ classes.grow } />
          <div className={ classes.sectionIcons }>
            { ['ready', 'started', 'incident', 'delayed'].includes(trip.status) ? <IconButton onClick={ handleQRReaderToggle } aria-label={ t('qrreader.label') } color="inherit">
              <Badge color="secondary">
                <CropFreeIcon />
              </Badge>
            </IconButton> : null }
            <IconButton onClick={ handleNotificationsToggle } color="inherit">
              <Badge badgeContent={ notifications.filter(notification => !notification.UserNotification.seen).length } color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </CustomAppBar>
      <Toolbar />
      <SwipeableDrawer
        open={ state.open }
        onClose={ toggleDrawer(false) }
        onOpen={ toggleDrawer(true) }
        className={ classes.drawer }
      >
        <div
          className={ classes.drawer }
          role="presentation"
          onClick={ toggleDrawer(false) }
          onKeyDown={ toggleDrawer(false) }
        >
          <img className={ classes.logo } src={ logo } alt="" />
          <span className={ classes.globalVersion }>v{ globalVersion } ({ process.env.REACT_APP_LAST_COMMIT_SHA }){ IS_STAGE ? ' Stage' : '' }</span>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={ localStorage.getItem('name') } src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={ localStorage.getItem('name') }
                secondary={ localStorage.getItem('email') }
                className={ classes.userInfo }
              />
            </ListItem>
          </List>
          <IconButton onClick={ e => handleThemeChange(e) } className={ classes.theme } color="primary" aria-label={ t('global.theme') }>
            { theme === 'light' ? <Brightness4Icon /> : <Brightness7Icon /> }
          </IconButton>
          <Divider />
          <List className={ classes.drawerItem }>
            <ListItemLink to="/" primary={ t('menu.home') } icon={ <HomeIcon /> } />
            <ListItemLink to="/trips" primary={ t('menu.trips') } icon={ <CardTravelIcon /> } />
          </List>
          {/* <ListItem button onClick={ toggleNestedItem('moreOpen') }>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="More" />
            { state.moreOpen ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse in={ state.moreOpen } timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemLink classes={ classes.nestedItem } to="/dashboard" primary="Dashboard" icon={ <DashboardIcon /> } />
              <ListItemLink classes={ classes.nestedItem } to="/settings" primary="Settings" icon={ <SettingsIcon /> } />
            </List>
          </Collapse> */}
          <MenuIllustration />
          <List className={ classes.drawerLogout }>
            <ListItem button onClick={ () => props.logout() }>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={ t('menu.logout') } />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
      <QRReader open={ state.qrReaderOpen } toggle={ handleQRReaderToggle } trip={ trip } />
      <Notifications open={ state.notificactionsOpen } toggle={ handleNotificationsToggle } notifications={ notifications.filter(notification => !notification.UserNotification.read) } />
      <NFC />
    </div>
  );
}

AppMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default AppMenu;
