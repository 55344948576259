import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { StoreProvider } from 'easy-peasy';
import store from './store/store';

import App from './App';

import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
import en from './translations/en.json';
import es from './translations/es.json';

setDefaultTranslations({en, es});
setDefaultLanguage( ['en', 'es'].includes(localStorage.getItem('language')) ? localStorage.getItem('language') : 'es' );
Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});

ReactDOM.render(
  <StoreProvider store={ store }>
    <Router>
      <App />
    </Router>
  </StoreProvider>,
  document.getElementById('root')
);
