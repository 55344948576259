import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import SwipeableViews from 'react-swipeable-views';

import CustomCard from '../components/CustomCard';
import LoadingCard from '../components/LoadingCard';

import { handleEndpointErrors, formatDate, formatTime } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `reservations-tabpanel-${ index }` }
      aria-labelledby={ `reservations-tab-${ index }` }
      { ...other }
    >
      { value === index && <Box p={ 3 }>{ children }</Box> }
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `reservations-tab-${ index }`,
    'aria-controls': `reservations-tabpanel-${ index }`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    '& .react-swipeable-view-container': {
      minHeight: 'calc(100vh - 104px)',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 112px)',
      },
    },
  },
  cardTitleIcon: {
    verticalAlign: 'sub'
  }
}));

const Trips = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [state, setState] = React.useState({
    tab: 0,
    snackbarError: false
  });

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const trips = useStoreState(state => state.trips.items);
  const tripsLoading = useStoreState(state => state.trips.loading);
  const tripLoadingCard = useStoreState(state => state.trips.loadingCard);
  // const tripsError = useStoreState(state => state.trips.error);
  const getTrips = useStoreActions(actions => actions.trips.getTrips);

  React.useEffect(() => {
    getTrips().then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log(tripsState);
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setState({ ...state, tab: newValue });
  };

  const handleChangeIndex = index => {
    setState({ ...state, tab: index });
  };

  // const onCancelHandler = (reservationId) => event => {
  //   console.log('cancel!!!', reservationId);
  // };

  // const onDetailHandler = (id) => event => {
  //   props.history.push({ pathname: `${ props.match.path }/detail/${ id }` });
  // };

  const t = useTranslation();

  storeMenuTitle(t('trips.pageTitle'));

  return (
    <div className={ classes.root }>
      <AppBar color="inherit" position="static">
        <Tabs value={ state.tab } onChange={ handleChange } variant="fullWidth" aria-label={ t('trips.aria') }>
          <Tab label={ t('trips.upcoming') } { ...a11yProps(0) } />
          <Tab label={ t('trips.past') } { ...a11yProps(1) } />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ state.tab }
        onChangeIndex={ handleChangeIndex }
      >
        <TabPanel value={ state.tab } index={ 0 } dir={ theme.direction }>
          { tripsLoading ? <LoadingCard length={ 4 } height={ 207 } /> :
            trips.upcoming && trips.upcoming.length ? trips.upcoming.map(trip => (
              tripLoadingCard === trip.id ?
                <LoadingCard key={ trip.id } length={ 1 } height={ 207 } /> :
                <CustomCard
                  key={ trip.id }
                  type="upcoming"
                  status={ trip.status }
                  tags={ [
                    trip.route.type.toLowerCase() === 'dtd' ? 'dtd' : 'p',
                    trip.route.direction,
                    ...(trip.extra ? ['extra'] : []),
                  ] }
                  route={{
                    name: trip.route.name,
                    origin: { name: trip.route.origin.description, time: formatTime(trip.schedule.departureTime) },
                    destination: { name: trip.route.destination.description, time: formatTime(trip.schedule.arrivalTime) },
                  }}
                  date={ `${ formatDate(trip.departureDate, t) }` }
                  ticket={ `ID: ${ trip.id }` }
                  vehicle={ `${ trip.vehicle.licensePlate } - ${ trip.vehicle.make } ${ trip.vehicle.model }, ${ trip.vehicle.year }` }
                  passengers={ trip.passengers?.length || 0 }
                  // primaryIconBtn={{
                  //   icon: <DeleteOutlineIcon color="primary" />,
                  //   handler: onCancelHandler(trip.id),
                  //   backText: t('trips.card.backCard.btnCancel')
                  // }}
                  // secondaryBtn={{
                  //   text: t('trips.card.detail'),
                  //   handler: onDetailHandler(trip.id),
                  //   backText: t('trips.card.backCard.btnBack')
                  // }}
                  backMsg={ t('trips.card.backCard.msgCancel') }
                  backIcon={ <DeleteOutlineIcon /> }
                />
            )) : (
              <CustomCard type="notFound" notFoundMsg={ t('trips.card.notFound') } />
            )
          }
        </TabPanel>
        <TabPanel value={ state.tab } index={ 1 } dir={ theme.direction }>
          { tripsLoading ? <LoadingCard length={ 4 } height={ 207 } /> :
            trips.past && trips.past.length ? trips.past.map(trip => (
              <CustomCard
                key={ trip.id }
                type="past"
                status={ trip.status }
                tags={ [
                  trip.route.type.toLowerCase() === 'dtd' ? 'dtd' : 'p',
                  trip.route.direction,
                  ...(trip.extra ? ['extra'] : []),
                ] }
                route={{
                  name: trip.route.name,
                  origin: { name: trip.route.origin.description, time: formatTime(trip.schedule.departureTime) },
                  destination: { name: trip.route.destination.description, time: formatTime(trip.schedule.arrivalTime) },
                }}
                date={ `${ formatDate(trip.departureDate, t) }` }
                ticket={ `ID: ${ trip.id }` }
                vehicle={ `${ trip.vehicle.licensePlate } - ${ trip.vehicle.make } ${ trip.vehicle.model }, ${ trip.vehicle.year }` }
                passengers={ trip.passengers?.length || 0 }
                // secondaryBtn={{
                //   text: t('trips.card.detail'),
                //   handler: onDetailHandler(trip.id),
                //   backText: t('trips.card.cancelBack')
                // }}
              />
            )) : (
              <CustomCard type="notFound" notFoundMsg={ t('trips.card.notFound') } />
            )
          }
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

Trips.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(Trips);
