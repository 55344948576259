import React from 'react';

import { fade, withTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles(theme => ({
// }));

const FromIcon = props => {
  // const classes = useStyles();

  return <SvgIcon { ...props }>
    <rect width="24" height="24" fill={ fade(props.theme.palette.secondary.main, 0.12) } />
    <rect opacity="0.4" x="4" y="4" width="16" height="16" fill={ fade(props.theme.palette.secondary.main, 0.32) } />
    <rect x="8" y="8" width="8" height="8" fill={ props.theme.palette.secondary.main } />
  </SvgIcon>;
}

export default withTheme(FromIcon);
