import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { fade, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  status: {
    ...theme.typography.button,
    borderColor: props => theme.palette.status[props.status] ? fade(theme.palette.status[props.status], 0.24) : fade(theme.palette.text.secondary, 0.24),
    borderRadius: theme.spacing(3),
    borderStyle: 'solid',
    borderWidth: '1px',
    color: props => theme.palette.status[props.status] ? theme.palette.status[props.status] : theme.palette.text.secondary,
    fontSize: '10px',
    lineHeight: '10px',
    margin: theme.spacing(.5),
    marginLeft: 0,
    padding: '10px',
    textTransform: 'uppercase',
  },
}));

const Status = props => {
  const { status, additionalClass } = props;
  const classes = useStyles(props);

  const t = useTranslation();
  const statusText = t(`global.status.${ status }`);

  return <span className={clsx( classes.status, additionalClass )}>{
    !statusText.includes('global.status') ? statusText : t(`global.status.default`)
  }</span>;
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

export default Status;
