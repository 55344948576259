import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as Sentry from '@sentry/browser';

import BackgroundIllustration from './BackgroundIllustration';

const useStyles = theme => ({
  root: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      marginTop: 'calc(50vh - 72px)',
      position: 'relative',
    },
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    const { classes, t } = this.props;

    if (this.state.hasError) {
      return (
        <div class={ classes.root }>
          <BackgroundIllustration />
          <Typography variant="h6">{ t('global.errors.boundary') }</Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(ErrorBoundary);
