import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  route: {
    paddingLeft: '15px',
    position: 'relative',
    '&::before': {
      backgroundColor: theme.palette.text.primary,
      content: '""',
      height: props => `calc(100% - ${ props.size * 2 + 40 }px)`,
      left: '3px',
      position: 'absolute',
      top: props => `${ props.size + 10 }px`,
      width: '2px',
    },
  },
  title: {
    '&::before': {
      border: `2px solid ${ theme.palette.text.primary }`,
      content: '""',
      height: '8px',
      left: 0,
      position: 'absolute',
      width: '8px',
    },
  },
  origin: {
    '&::before': {
      top: props => `${ props.size }px`,
    },
  },
  destination: {
    '&::before': {
      borderRadius: '50%',
      bottom: props => `${ props.size + 20 }px`,
    },
    '&::after': {
      borderColor: `${ theme.palette.text.primary } transparent transparent transparent`,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: '5px 4px 0 4px',
      bottom: props => `${ props.size + 20 - 2.5 }px`,
      content: '""',
      height: 0,
      left: 0,
      position: 'absolute',
      width: 0,
    },
  },
}));

const Route = props => {
  const { origin, destination, variant } = props;
  const titleVariant = !variant || variant === 'default' ? 'body1' : variant === 'small' ? 'body2' : 'body1';
  const classes = useStyles({
    ...props,
    size: titleVariant === 'body1' ? 8 : 6
  });

  return (
    <div className={ classes.route }>
      <Typography className={ clsx( classes.title, classes.origin ) } variant={ titleVariant } component="p">{ origin.name }</Typography>
      <Typography gutterBottom variant="body2" color="textSecondary" component="p">{ origin.time }</Typography>
      <Typography className={ clsx( classes.title, classes.destination ) } variant={ titleVariant } component="p">{ destination.name }</Typography>
      <Typography gutterBottom variant="body2" color="textSecondary" component="p">{ destination.time }</Typography>
    </div>
  );
}

Route.propTypes = {
  origin: PropTypes.exact({
    name: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }),
  destination: PropTypes.exact({
    name: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }),
  variant: PropTypes.oneOf(['default', 'small']),
};

export default Route;
