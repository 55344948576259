import { action, thunk } from 'easy-peasy';

import { getCurrentTrip, getTrips } from '../../services/trips/get';
import { postTrip, updateTripPassenger, addTripReservation } from '../../services/trips/post';

const tripsModel = {
  items: {
    upcoming: [],
    past: []
  },
  currentItem: {},
  loading: false,
  loadingPassenger: false,
  loadingCard: null,
  error: '',
  errorPassenger: '',
  getCurrentTrip: thunk(async (actions, payload = true) => {
    actions.setLoading(payload);
    actions.setError('');
    return await getCurrentTrip(actions);
  }),
  getTrips: thunk(async (actions, payload = true) => {
    actions.setLoading(payload);
    actions.setError('');
    return await getTrips(actions);
  }),
  storeGetCurrentTrip: action((state, payload) => {
    if (payload.length) {
      state.currentItem = payload[0];
    } else {
      state.currentItem = {};
    }
  }),
  storeGetTrips: action((state, payload) => {
    const today = new Date();
    if (payload.length) {
      state.items.upcoming =
        payload.filter(trip => !['completed', 'cancelled'].includes(trip.status) && today.getTime() < new Date(trip.departureDate.slice(0, -1)).getTime()).reverse();
      state.items.past =
        payload.filter(trip => ['completed', 'cancelled'].includes(trip.status) || today.getTime() > new Date(trip.departureDate.slice(0, -1)).getTime());
    } else {
      state.items.upcoming = [];
      state.items.past = [];
    }
  }),
  postTrip: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    await postTrip(actions, payload);
  }),
  storePostTrip: action((state, payload) => {
    state.currentItem.status = payload;
  }),
  updateTripPassenger: thunk(async (actions, payload, helpers) => {
    actions.setLoadingPassenger(true);
    actions.setErrorPassenger('');
    const passenger = helpers.getStoreState().trips.currentItem.passengers.find(passenger => passenger.id === payload.passengerId);
    await updateTripPassenger(actions, { passengerId: passenger.id, reservationId: passenger.reservation.id, status: payload.status, notes: payload.notes });
  }),
  storeUpdateTripPassenger: action((state, payload) => {
    state.currentItem.passengers.find(passenger => passenger.id === payload.passengerId).status = payload.status;
  }),
  addTripReservation: thunk(async (actions, payload) => {
    actions.setLoadingPassenger(true);
    actions.setErrorPassenger('');
    await addTripReservation(actions, payload);
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingPassenger: action((state, payload) => {
    state.loadingPassenger = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setErrorPassenger: action((state, payload) => {
    state.errorPassenger = payload?.error;
    if (payload?.error?.data?.errors[0] === 'Reservation not found' && payload?.passengerId) {
      state.currentItem.passengers.find(passenger => passenger.id === payload.passengerId).status = 'cancelled';
    }
  }),
}

export default tripsModel;
