import React from 'react';

import PropTypes from 'prop-types';

import { useStore, useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { handleEndpointErrors, transformDate, SNACKBAR_TIME, ERRORS } from '../shared/utilities';

import QrReader from 'react-qr-reader';

import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ ref } { ...props } />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    position: 'relative',
    '& .MuiToolbar-root': {
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${ theme.palette.primary.light }`,
      color: theme.palette.text.secondary,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  qrReader: {
    '& section': {
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        paddingTop: 'calc(100vh - 56px) !important'
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: 'calc(100vh - 64px) !important'
      }
    }
  }
}));

const QRReader = (props) => {
  const classes = useStyles();
  const { open, toggle, trip } = props;

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const updateTripPassenger = useStoreActions(actions => actions.trips.updateTripPassenger);
  const addTripReservation = useStoreActions(actions => actions.trips.addTripReservation);
  const getCurrentTrip = useStoreActions(actions => actions.trips.getCurrentTrip);

  const t = useTranslation();

  const handleQrLogic = passengerId => {
    const passengers = trip.passengers ? trip.passengers : [];
    const tripPassengersIds = passengers.map(passenger => passenger.id);
    const status = ['pending', 'ready'].includes(trip.status) ? 'onboard' : 'underway';
    toggle();
    if ( tripPassengersIds.includes(passengerId) ) {
      updateTripPassenger({ passengerId, status }).then(() => {
        const tripsState = store.getState().trips;
        const passengerName = tripsState.currentItem.passengers.find(p => p.id === passengerId)?.name;
        if (!tripsState.loadingPassenger && !tripsState.errorPassenger) {
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.passenger.updated', { passenger: passengerName }) });
        } else {
          handleEndpointErrors(tripsState, props, setSnackbar, t);
        }
      });
    } else {
      // if (passengers.length < trip.route.capacity) {
        const reservationData = {
          routeId: trip.route.id,
          tripId: trip.id,
          passengerId: passengerId,
          routeScheduleId: trip.schedule.id,
          dates: [trip.departureDate.split('T')[0]],
          placeId: trip.route.destination.id,
          status,
          ignoreReservationWindow: true,
          ignoreVehicleCapacity: true,
          source: 'qr_code_driver'
        };
        addTripReservation(reservationData).then(() => {
          const tripsState = store.getState().trips;
          if (!tripsState.loadingPassenger && !tripsState.errorPassenger) {
            // console.log(tripsState);
            getCurrentTrip(false).then(() => {
              const tripsState = store.getState().trips;
              const passengerName = tripsState.currentItem.passengers.find(p => p.id === passengerId)?.name;
              if (!tripsState.loading && !tripsState.error) {
                // console.log(tripsState);
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.passenger.updated', { passenger: passengerName }) });
              } else {
                handleEndpointErrors(tripsState, props, setSnackbar, t);
              }
            });
          } else {
            tripsState.error = tripsState.errorPassenger;
            handleEndpointErrors(tripsState, props, setSnackbar, t);
          }
        });
      // } else {
      //   setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('qrreader.maxCapacity', { capacity: trip.route.capacity }) });
      // }
    }
  }

  const handleQrScan = (data, props) => {
    if (data) {
      const passengerId = +JSON.parse(data)?.moveOnPassengerId;
      const passengerExpirationDate = JSON.parse(data)?.moveOnPassengerExpirationDate;
      const isTurist = passengerExpirationDate !== null && passengerExpirationDate !== undefined;
      const todayDateTime = new Date(new Date().toLocaleDateString('en')).getTime();
      const passengerDateTime = isTurist ? new Date(new Date(transformDate(passengerExpirationDate)).toLocaleDateString('en')).getTime() : null;
      const isValidTurist = isTurist && passengerDateTime >= todayDateTime;
      if ( (!isTurist && passengerId) || (isValidTurist && passengerId) ) { // If not turist and has a valid ID OR is turist and has a valid expiration date and ID
        handleQrLogic(passengerId);
      } else if ( isTurist && !isValidTurist ) { // If is turist and does not have a valid expiration date
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('qrreader.expireDate') });
      }
    }
  };

  const handleQrError = error => {
    if (error.toString() === ERRORS.DEVICE_NOT_FOUND) {
      setSnackbar({ show: true, autoHideDuration: 8000, severity: 'error', message: t('global.errors.cameraNotFound') });
    } else if (error.toString() === ERRORS.PERMISSION_DENIED || error.toString() === ERRORS.PERMISSION_DISMISSED) {
      setSnackbar({ show: true, autoHideDuration: 8000, severity: 'error', message: t('global.errors.cameraNotEnabled') });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.qrreader') });
    }
  };

  return (
    <Dialog className={ classes.root } fullScreen open={ open } onClose={ toggle } TransitionComponent={ Transition }>
      <AppBar className={ classes.appBar }>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={ toggle } aria-label="close">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={ classes.title }>
            { t('qrreader.label') }
          </Typography>
        </Toolbar>
      </AppBar>
      <QrReader
        delay={ 300 }
        onError={ handleQrError }
        onScan={ data => handleQrScan(data, props) }
        className={ classes.qrReader }
      />
    </Dialog>
  );
}

QRReader.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  trip: PropTypes.object.isRequired,
};

export default QRReader;
