import { action, thunk } from 'easy-peasy';

import postLogin from '../../services/login/post';

const loginModel = {
  item: {},
  loading: false,
  error: '',
  postLogin: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await postLogin(actions, payload);
  }),
  storePostLogin: action((state, payload) => {
    state.item = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default loginModel;
