import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const postTrip = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips/${ payload.id }/events`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    status: payload.status,
    notes: ''
  }
})
.then(response => {
  actions.storePostTrip(payload.status);
  actions.setLoading(false);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateTripPassenger = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload.reservationId }/events`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    status: payload.status,
    notes: payload.notes || ''
  }
})
.then(response => {
  actions.setLoadingPassenger(false);
  actions.storeUpdateTripPassenger(payload);
})
.catch(error => {
  actions.setLoadingPassenger(false);
  actions.setErrorPassenger({ error: error.response, passengerId: payload.passengerId });
  logSentryEndpointError(error);
});

export const addTripReservation = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  actions.setLoadingPassenger(false);
})
.catch(error => {
  actions.setLoadingPassenger(false);
  actions.setErrorPassenger({ error: error.response });
  logSentryEndpointError(error);
});
